(function() {
	'use strict';
	var lang = document.documentElement.getAttribute('lang');

	if (lang == 'ja-jp') {
		var testimonialsJP = tns({
			"container": ".slider-fad-testimonials-jp",
			"controlsContainer": ".slider-fad-testimonials-jp-controls",
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"navPosition": "bottom",
			"gutter": 24,
			"slideBy": "page",
			"responsive": {
				"768": {
					"items": 2
				},
				"992": {
					"gutter": 32
				},
				"1280": {
					"items": 2
				}
			}
		});

		// Create a slide counter bellow the Carousel
		avm.require('avast.web.slideCounter')(testimonialsJP);

	} else {
		var testimonials = tns({
			"container": ".slider-fad-testimonials",
			"controlsContainer": ".slider-fad-testimonials-controls",
			"preventScrollOnTouch": "force",
			"controlsPosition": "bottom",
			"navPosition": "bottom",
			"gutter": 24,
			"slideBy": "page",
			"responsive": {
				"992": {
					"gutter": 32
				}
			}
		});

		// Create a slide counter bellow the Carousel
		avm.require('avast.web.slideCounter')(testimonials);
	}


		// **************************************************************************
		// Avaibility links tracking
		// **************************************************************************
		// Select all links within the hero-note and bft-hint divs
		const links = document.querySelectorAll('.hero-note a, .bft-hint a');

		// Function to extract platform name based on link text
		function getPlatformName(linkText) {
			if (linkText.includes('Mac')) return 'Mac';
			if (linkText.includes('Android')) return 'Android';
			if (linkText.includes('iOS')) return 'iOS';
			if (linkText.includes('PC')) return 'PC';
			return 'Unknown';
		}
	
		// Add click event listener to each link
		links.forEach(function(link) {
			link.addEventListener('click', function(event) {	
				// Extract platform name based on the link text
				const platformName = getPlatformName(this.textContent.trim());
	
				// Push info to sdl object
				window.sdl = window.sdl || [];
				window.sdl.push({
					user: {
						click: {
							element: {
								actionType: "below_cta_platform_click",
								id: platformName // name of the link the user clicked on
							}
						}
					},
					event: "user.click.element"
				});
			});
		});
		// **************************************************************************

})();
